import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { Button, Form } from "react-bootstrap";
import Layout from "../components/layout";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SEO from "../components/seo";
import SummaryBlock from "../components/SummaryBlock";
import { Checkbox } from "@material-ui/core";
import axios from "axios";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Loader from "../components/Loader";
const StyledWrapper = styled.div`
  width: 100%;
  padding: 5% 0;
  overflow: scroll;

  .form-control {
    height: 69px;
    background-color: #ececec;

    @media (max-width: 600px) {
      height: 50px;
      width: 100%;
    }
  }

  .form-label {
    font-family: "Nunito Sans";
    font-size: 21px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 28px;
    margin-top: 20px;
    @media (max-width: 600px) {
      font-size: 15px;
      line-height: normal;
    }
  }

  .submit-form {
    background-color: #5db9db;
    font-size: 21px;
    font-weight: bold;
    width: 100%;
    border: 0px;
  }

  .form-group {
    margin-bottom: 0;
  }

  .anchor-class {
    color: black;
    text-decoration: underline;
  }

  .checkbox-link {
    display: inline-block;
    color: black;
    text-decoration: underline;
  }

  .fail-text {
    padding-top: 10px;
    padding-right: 80px;
    font-weight: bolder;
    color: #e43d5a;
  }

  .required-text {
    color: #e43d5a;
  }

  .captcha {
    padding: 20px 0 10px 0;
  }

  .policy-statement {
    padding-right: 20%;
  }

  .link {
    text-decoration: underline;
    color: black;
  }

  select {
    padding: 20px 0;
    display: flex;
    width: 100%;
    border: 1px solid #ced4da;
    background: #ececec;
    outline: none;
  }

  option {
    margin-left: 100px;
  }

  .toast-color {
    background: #5db9db;
  }

  .progressClass {
    background: white;
  }

  .toastBodyClass {
    color: black;
  }

  .MuiFormGroup-root {
    display: flex !important;
    flex-direction: row !important;
  }

  .checkboxSet1,
  .checkboxSet2 {
    display: flex;
    flex-direction: column;
  }

  input {
    margin-right: 8px;
  }

  label {
    margin-bottom: 10px;
  }
  .test-1 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  .statusSubmit {
    font-size: 1.1rem;
    font-weight: 800;
  }
  .text-link {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
  }
  .privacy-test {
    margin-top: 2rem;
    font-family: Nunito Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: left;
  }
  .form-check {
    padding-top: 0rem !important;
    padding-left: 2rem;
  }
  .form-check-label {
    font-size: 18px;
  }
  .form-check-input {
    transform: scale(1.2);
  }
  .form-check-label{
    font-size:18px;
    font-weight: 400;
  }
  .bold-text {
    font-weight: 1000;
  }
`;

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Webinars />
    </QueryClientProvider>
  );
}
const fetchData = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/aspire`);
};
const fetchEvents = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/webinar-event-names`);
};

function Webinars() {
  const [newData, setNewData] = useState(null);
  const [eventData, setEventData] = useState(null);
  const { data: pageData } = useQuery("webinar data", fetchData, {staleTime: Infinity});
  const { data: events } = useQuery("events", fetchEvents, {staleTime: Infinity});
  useEffect(() => {
    if (pageData !== undefined) {
      setNewData(pageData.data);
    }
    if (events !== undefined) {
      setEventData(events.data);
    }
  }, [pageData, events]);

  const { register, handleSubmit, watch, errors, setValue, reset } = useForm({
    mode: "onChange",
  });
  const [error, setError] = useState("");
  const [feedbackMsg, setFeedbackMsg] = useState(null);
  const [selectReference, setSelectReference] = useState("");
  const [selectYear, setSelectYear] = useState("");
  const enkiUrl = process.env.GATSBY_ENKI_URL;
  const [schoolTextSuggestions, setSchoolTextSuggestions] = useState(null);
  const [disableBTN, setDisableBTN] = useState(false);
  const [eventsClone, setEventsClone] = useState([]);
  const [student, setStudent] = useState(false);
  const [parent, setParent] = useState(false);
  const [teacher, setTeacher] = useState(false);
  const [other, setOther] = useState(false);

 
  const notifyToast = () => {
    return toast(
      "Thank you for registering. We will be in touch soon with joining instructions",
      {
        className: "toast-color",
        progressClassName: "progressClass",
        bodyClassName: "toastBodyClass",
        autoClose: false,
      }
    );
  };

  const filterGcseSchoolCode = function (val) {
    var url = `${enkiUrl}/ukschool/autocomplete?term=` + val;
    const requestOptions = {
      method: "GET",
    };
    fetch(url, requestOptions)
      .then(response => response.json())
      .then(data => {
        let results = data.filter(postCode => {
          return postCode.value.toLowerCase().startsWith(val.toLowerCase());
        });
        setSchoolTextSuggestions(results);
      });
  };

  const onSubmit = formData => {
    if (formData["Receiving emails from upReach about relevant Webinars"] === false) {
      setError("Please agree to our Privacy Policy and consent to Receiving emails from upReach about relevant Webinars");
      return;
    }
    if (formData["phone"] !== "") {
      if (formData["phone"].length != 11) {
        setError("Please enter a 11 digit phone number with zero as the first number");
        return;
      }
      if (formData["phone"][0] != 0) {
        setError("Please enter a 11 digit phone number with zero as the first number");
        return;
      }
    }
    if (formData["schoolYear"] === "null") {
      setError("Please select your Current School Year");
      return;
    }
    if (formData["schoolAttend"] === "null") {
      setError("Please select your Current School Year");
      return;
    }
    if (formData["schoolParents"] === "null") {
      setError("Please select your Current School Year");
      return;
    }
    if (formData["schoolMeals"] === "null") {
      setError("Please select your Current School Year");
      return;
    }
    if (formData["best_describes_you"] === "null") {
      setError("Please select your Current School Year");
      return;
    }
    if (document.querySelector("#school_name_object").value == "" || !JSON.parse(document.querySelector("#school_name_object").value) instanceof Object) {
      setError("Please select your school name");
      return;
    }
    const userSelectedStatements = [];

    eventsClone.forEach(doc => {
      if (doc.isChecked) {
        userSelectedStatements.push(doc.EventName);
      }
    });
    for(let event of eventsClone){
      event.isChecked == true && (event.isChecked = false)
    }

    fetch("https://formspree.io/f/xnqlldez", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        formData,
        userSelectedStatements,
      }),
    }).then(function (response) {
      if (response.status == 200) {
        setDisableBTN(true);
        reset();
        notifyToast();
        setError("");
      } else {
        return response.json().then(data => setError(data.message));
      }
    });
  };

  useEffect(() => {
    if (eventData && eventData.length >= 1) {
      setEventsClone(eventData);
    }
  }, [eventData]);

  return (
    <Layout>
      <StyledWrapper className="white-background">
        <SEO title="Live Webinar" />
        {newData === null && <Loader />}
        {newData && (
          <h1
            className="d-flex justify-content-center mb-0 py-3"
            style={{ color: "#5DB9DB" }}
          >
            LIVE WEBINARS
          </h1>
        )}

        {newData && newData.showWebinarSummary && (
          <section className="white-background py-3">
            <SummaryBlock summary={newData.WebinarSummary} />
          </section>
        )}
        {newData && (
          <Form
            className="padding-right-8 padding-left-8"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Form.Label>First Name<span className="required-text">*</span></Form.Label>
            <Form.Control
              type="text"
              ref={register({ required: true })}
              name="first_name"
              placeholder="Enter your first name"
            />
            {errors.first_name && (<p className="mb-0 text-danger font-weight-bold">Please enter your first name</p>)}
            
            <Form.Label>Surname<span className="required-text">*</span></Form.Label>
            <Form.Control
              type="text"
              ref={register({ required: true })}
              name="surname"
              placeholder="Enter your last name"
            />
            {errors.surname && (<p className="mb-0 text-danger font-weight-bold">Please enter your last name</p>)}
            
            <Form.Group controlId="formGridEmail">
              <Form.Label>Personal Email <span className="required-text">*</span></Form.Label>
              <Form.Control
                ref={register({ 
                  required: true,
                  pattern: /^(([^<>()\[\]\\.,;:\s@“]+(\.[^<>()\[\]\\.,;:\s@“]+)*)|(“.+“))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                })}
                name="email"
                placeholder="Enter your email address"
                type="email"
              />
              <Form.Text className="text-muted test-1">
              <span className="bold-text">Please note:</span> using your school email address may result in
                emails being filtered out of your inbox. We would advise using
                your personal email address to reduce the likelihood of this
                happening.
              </Form.Text>
            </Form.Group>
            {errors.email && (<p className="mb-0 text-danger font-weight-bold">Please enter your personal email</p>)}
            
            <Form.Group controlId="formGridphone">
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control
                ref={register({ required: true })}
                name={"phone"}
                type="phone"
                placeholder="Enter your phone number"
              />
              <Form.Text className="text-muted test-1">
              <span className="bold-text">Please note:</span> we will use this number to text you reminders about upcoming events you have signed up for, or - if you're a student - programmes you may be eligible for.
              </Form.Text>
            </Form.Group>
            <Form.Label>Which of the following best describes you{" "}
            <span className="required-text"> *</span>
            </Form.Label>
            <Form.Group>
              <Form.Check
              id="student"
              htmlFor="student"
              type="radio"
              label="I am student signing up for myself"
              ref={register({ required: true })}
              name="best_describes_you"
              value="I am student signing up for myself"
              onClick={e => {
                setStudent(true)
                setParent(false)
                setTeacher(false)
                setOther(false)
                // setCommonLayoutData({ ...commonLayoutData, ...getValues()});
              }}
              checked={student}
            />
              <Form.Check
              id="Parent"
              htmlFor="Parent"
              type="radio"
              label="I am a parent / guardian signing up on behalf of a student"
              ref={register({ required: true })}
              name="best_describes_you"
              value="I am a parent / guardian signing up on behalf of a student"
              onClick={e => {
                setParent(true)
                setStudent(false)
                setTeacher(false)
                setOther(false)
                // setCommonLayoutData({ ...commonLayoutData, ...getValues()});
              }}
              checked={parent}
            />
            <Form.Check
              id="Teacher"
              htmlFor="Teacher"
              type="radio"
              label="I am a teacher/careers advisor signing up on behalf of my students"
              ref={register({ required: true })}
              name="best_describes_you"
              value="I am a teacher / careers advisor signing up on behalf of my students"
              onClick={e => {
                setTeacher(true)
                setParent(false)
                setStudent(false)
                setOther(false)
                // setCommonLayoutData({ ...commonLayoutData, ...getValues()});
              }}
              checked={teacher}
            />
            <Form.Check
              id="Other"
              htmlFor="Other"
              type="radio"
              label="Other"
              ref={register({ required: true })}
              name="best_describes_you"
              value={"Other"}
              onClick={e => {
                setParent(false)
                setStudent(false)
                setOther(true)
                setTeacher(false)
                // setCommonLayoutData({ ...commonLayoutData, ...getValues()});
              }}
              checked={other}
            />
          </Form.Group>
          <div>
              {other && (
                <div>
                  <Form.Label>If other, please give details</Form.Label>
                  <Form.Control
                    name="best_describes_you_other"
                    placeholder=""
                    type="text"
                    ref={register({ required: other })}
                  />
                  {errors.best_describes_you_other && (<p className="mb-0 text-danger font-weight-bold">Please provide your other details</p>)}
                </div>
              )}
            </div>
            {errors.best_describes_you && (<p className="mb-0 text-danger font-weight-bold">Please select the option</p>)}

            <Form.Label>Please select your school<span className="required-text">*</span></Form.Label>
            <Form.Control type="text" ref={register({ required: true })} autoComplete="off"
              id="schoolName" name="schoolName" placeholder="Enter your school name"
              onChange={e => {
                e.target.value.length > 2 ? filterGcseSchoolCode(e.target.value) : setSchoolTextSuggestions(null);
                if(e.target.value.length > 2){
                  document.querySelector("#school_name_object").value = "";
                }
              }}
            />
            {schoolTextSuggestions != null && schoolTextSuggestions.length > 0 && (
              <div className="autocomplete_sugestions shadow p-3">
                {schoolTextSuggestions.map(x => (
                  <div>
                    <a href={x.id} className="pb-1 text-reset text-decoration-none"
                      onClick={e => {
                        e.preventDefault();
                        document.querySelector("#schoolName").value = x.value;
                        document.querySelector("#school_name_object").value = JSON.stringify(x);
                        setSchoolTextSuggestions(null);
                      }}> {x.value} </a>
                  </div>
                ))}
              </div>
            )}
            <Form.Text className="text-muted test-1">
            <span className="bold-text">Parent or guardian?</span> Please select the school attended by the student you're signing up on behalf of.
            </Form.Text>
            <Form.Text className="text-muted test-1">
            <span className="bold-text">Teacher or careers advisor?</span> Please select the school where you currently teach.            
            </Form.Text>
            
            <Form.Control type="hidden" ref={register} name="school_name_object" id="school_name_object"/>
            {errors.schoolName && (<p className="mb-0 text-danger font-weight-bold">Please enter your school name</p>)}

            <Form.Group className="mb-3" controlId="formBasicSector">
              <Form.Label className="form-label">Please select your current School Year, and country of study:<span className="required-text"> *</span></Form.Label>
              <Form.Control ref={register({ required: true })} as="select" name="schoolYear" className="px-2" onChange={e => setSelectYear(e.target.value)}>
                <option value="" selected>Select your current school year</option>
                <option value="Not applicable - I am a parent/guardian/teacher/careers advisor">Not applicable - I am a parent/guardian/teacher/careers advisor</option>
                <option value="Year 11 or under (England and Wales)">Year 11 or under (England and Wales)</option>
                <option value="Year 12 (England and Wales)">Year 12 (England and Wales)</option>
                <option value="Year 13 (England and Wales)">Year 13 (England and Wales)</option>
                <option value="S4 or under (Scotland)">S4 or under (Scotland)</option>
                <option value="S5 (Scotland)">S5 (Scotland)</option>
                <option value="S6 (Scotland)">S6 (Scotland)</option>
                <option value="Year 12 and under (Northern Ireland)">Year 12 and under (Northern Ireland)</option>
                <option value="Year 13 (Northern Ireland)">Year 13 (Northern Ireland)</option>
                <option value="Year 14 (Northern Ireland)">Year 14 (Northern Ireland)</option>
                <option value="Other">Other</option>
              </Form.Control>
              <Form.Text className="text-muted test-1">
              <span className="bold-text">Parent or guardian?</span> Please select the year group of the student you're signing up on behalf of.
            </Form.Text>
            <Form.Text className="text-muted test-1">
            <span className="bold-text">Teacher or careers advisor?</span> Please select not applicable.            
            </Form.Text>
              {errors.schoolYear && (<p className="mb-0 text-danger font-weight-bold">Please select your current school year</p>)}
              <div>
              {selectYear === "Other" && (
                <div>
                  <Form.Label>If other, please give details</Form.Label>
                  <Form.Control
                    name="schoolYear_other"
                    placeholder=""
                    type="text"
                    ref={register({ required: selectYear === "Other" })}
                  />
                  {errors.hear_about_us_other && (<p className="mb-0 text-danger font-weight-bold">Please provide your other details</p>)}
                </div>
              )}
            </div>

            </Form.Group>

            <Form.Label>
              Where did you hear about us?
              <span className="required-text">*</span>
            </Form.Label>
            <select
              name="Hear_About_Us"
              className="px-2"
              ref={register({ required: true })}
              onChange={e => setSelectReference(e.target.value)}
            >
              <option value="" selected>Select your option</option>
            {newData &&
            newData.HearAboutUS.map(option => (
              <option key={option.id} value={option.HearAboutUs}>
                {option.HearAboutUs}
              </option>
            ))
            }
              {/* <option value="">Select your option</option>
              <option value="Poster">Poster at School/Sixth Form</option>
              <option value="School Assembly/Presentation">
                School assembly/presentation
              </option>
              <option value="Tutor presentation">Tutor presentation</option>
              <option value="Social Media">
                Social Media (Facebook/Instagram etc)
              </option>
              <option value="Teacher">Teacher</option>
              <option value="Career Advisor">Career Advisor</option>
              <option value="Parent/Guardian">Parent/Guardian</option>
              <option value="Student">Student</option>
              <option value="Other">
                Other (Please provide more detail in the box below)
              </option> */}
            </select>
            {errors.Hear_About_Us && (<p className="mb-0 text-danger font-weight-bold">Please select your option</p>)}

            <div>
              {selectReference === "Other" && (
                <div>
                  <Form.Label>If other, please give details</Form.Label>
                  <Form.Control
                    name="hear_about_us_other"
                    placeholder=""
                    type="text"
                    ref={register({ required: selectReference === "Other" })}
                  />
                  {errors.hear_about_us_other && (<p className="mb-0 text-danger font-weight-bold">Please provide your other details</p>)}
                </div>
              )}
            </div>

          <Form.Group className="mb-3" controlId="formBasicSector">
              <Form.Label className="form-label">Which type of school did you attend between the ages of 11 and 16?<span className="required-text"> *</span></Form.Label>
              <Form.Control ref={register({ required: true })} as="select" name="schoolAttend" className="px-2">
                <option value="" selected>Select the type of school</option>
                <option value="A UK state school">A UK state school</option>
                <option value="A UK independent or fee-paying school">A UK independent or fee-paying school</option>
                <option value="Attended school outside the UK">Attended school outside the UK</option>
                <option value="I don't know">I don't know</option>
              </Form.Control>
              <Form.Text className="text-muted test-1">
              <span className="bold-text">Parent or guardian?</span> Please select the type of school attended by the student you're signing up on behalf of.
            </Form.Text>
            <Form.Text className="text-muted test-1">
            <span className="bold-text">Teacher or careers advisor?</span> Please select the type of school where you currently teach.
            </Form.Text>
              {errors.schoolAttend && (<p className="mb-0 text-danger font-weight-bold">Please select the type of school</p>)}
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicSector">
              <Form.Label className="form-label">Did either of your parents attend university and gain a degree by the time you were 18?<span className="required-text"> *</span></Form.Label>
              <Form.Control ref={register({ required: true })} as="select" name="schoolParents" className="px-2">
                <option value="" selected>Select your option</option>
                <option value="Yes, one or both of my parents attended university">Yes, one or both of my parents attended university</option>
                <option value="No, neither of my parents attended university">No, neither of my parents attended university</option>
                <option value="Do not know/not sure">Do not know / not sure</option>
                <option value="Not applicable - I am a teacher/careers advisor">Not applicable - I am a teacher/careers advisor</option>
              </Form.Control>
              <Form.Text className="text-muted test-1">
              <span className="bold-text">Parent or guardian?</span> Please answer on behalf of the student.
              </Form.Text>
            <Form.Text className="text-muted test-1">
            <span className="bold-text">Teacher or careers advisor?</span> Please select "Not applicable".
            </Form.Text>
              {errors.schoolParents && (<p className="mb-0 text-danger font-weight-bold">Please select your option</p>)}
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicSector">
              <Form.Label className="form-label">Have you been eligible for free school meals at any point?<span className="required-text"> *</span></Form.Label>
              <Form.Control ref={register({ required: true })} as="select" name="schoolMeals" className="px-2">
                <option value="" selected>Select your option</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="I don't know">I don't know</option>
                <option value="I don't know/not">I went to school overseas</option>
                <option value="Not applicable - I am a teacher/careers advisor">Not applicable - I am a teacher / careers advisor</option>
              </Form.Control>
              <Form.Text className="text-muted test-1">
              <span className="bold-text">Parent or guardian?</span> Please answer on behalf of the student.
            </Form.Text>
            <Form.Text className="text-muted test-1">
            <span className="bold-text">Teacher or careers advisor?</span> Please select "Not applicable".
            </Form.Text>
              {errors.schoolMeals && (<p className="mb-0 text-danger font-weight-bold">Please select your option</p>)}
          </Form.Group>
            
            <Form.Label>
              Please select all the webinars that you will attend from the list below.
            </Form.Label>
            <Form.Text className="text-muted test-1 pb-3">
            <span className="bold-text">Please note most webinars run from 5:00-6:00pm unless stated.</span> All webinars are <span className="bold-text">free</span>, are hosted on <span className="bold-text">Zoom</span>, and you can sign up to an <span className="bold-text">unlimited amount</span> (the more sessions you can attend, the higher impact the webinars can have on your confidence and understanding).
            </Form.Text>
            {eventData &&
              eventData.length > 0 &&
              eventData
                .sort((a, b) => a.orderNo - b.orderNo)
                .map((item, index) => (
                  <div>
                    <label>
                      <input
                        value={item.EventName}
                        type="checkbox"
                        checked={item.isChecked}
                        onClick={() => {
                          const tempClonnedEvents = [...eventsClone];
                          tempClonnedEvents[
                            index
                          ].isChecked = !tempClonnedEvents[index].isChecked;
                          setEventsClone([...tempClonnedEvents]);
                        }}
                      />
                      {item.EventName}
                    </label>
                  </div>
                ))}
            <p className="privacy-test">
              {" "}
              <strong>
                I have read and understood the{" "}
                <AnchorLink to="/privacy-policy" className="anchor-class">
                  Privacy Policy
                </AnchorLink>{" "}
                and consent to:
              </strong>
            </p>
            <div className="form-check pt-3">
              <input
                className="form-check-input"
                type="checkbox"
                name="Receiving emails from upReach about relevant Webinars"
                ref={register({ required: false })}
              />
              <label
                className="form-check-label"
                style={{ paddingBottom: "10px" }}
              >
                Receiving emails from upReach about relevant Webinars
                <span className="required-text"> * </span>
              </label>
            </div>
            <div className="form-check pt-3">
              <input
                className="form-check-input"
                type="checkbox"
                name="Receiving emails regarding upReach's free careers support programmes"
                ref={register({ required: false })}
              />
              <label
                className="form-check-label"
                style={{ paddingBottom: "10px" }}
              >
                Receiving emails regarding upReach's free careers support
                programmes. We will not pass on your details to any third
                parties.
              </label>
            </div>

            <p className="statusSubmit text-muted">
              We will be in touch within three working days with your
              registration links - if you have not heard from us by then, please
              check your junk folder and/or contact
              <span className="text-link"> aspire@upreach.org.uk</span>.
            </p>

            <Button className="submit-form" type="submit" disabled={disableBTN}>
              {disableBTN == false ? "SUBMIT" : "SUBMITTED"}
            </Button>
            {error != "" ? <p className="fail-text">{error}</p> : <></>}
            <ToastContainer />
          </Form>
        )}
      </StyledWrapper>
    </Layout>
  );
}
